<template>
  <div data-cy="benchmarks">
    <PageHeaderDisplay
      title="Benchmarks"
      sub-title="An insight into how you're portfolios and assets are performing against various benchmarks."
      category="Benchmarks"
      category-logo="fa-chart-bar"
      :category-route="{ name: $route.name }"
    />
    <TabView route-name="benchmarks" :tabs="tabs" :loading="loading" :exact="false">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Icon from '@/components/Icon';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'BenchmarkView',
  components: {
    PageHeaderDisplay,
    TabView
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      company: 'company/company'
    }),
    tabs() {
      return [
        {
          name: 'CRREM',
          route: 'benchmarks-crrem',
          icon: 'fa-chart-line',
          isComingSoon: false
        },
        {
          name: 'REEB',
          route: 'benchmarks-reeb',
          icon: 'fa-seedling',
          isComingSoon: true
        },
        {
          name: 'LETI',
          route: 'benchmarks-leti',
          icon: 'fa-buildings',
          isComingSoon: true
        },
        {
          name: 'ETN',
          route: 'benchmarks-etn',
          icon: Icon,
          isComingSoon: true
        }
      ];
    }
  },
  async mounted() {
    this.loading = true;

    this.loading = false;
  },
  methods: {
    ...mapActions({
      listAccounts: 'account/list',
      getCompany: 'company/get'
    }),
    ...mapMutations({
      setSuppliers: 'scraperRun/SET_SUPPLIERS'
    })
  }
};
</script>
