<template>
  <div class="etn-icon-wrapper">
    <div class="etn-icon mb-2">
      <div class="left"></div>
      <div class="right"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: String,
      default: 'lg'
    },
    variant: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    spinnerClass() {
      return { [`spinner-${this.size}`]: this.size !== 'md', spinner: this.size === 'md', [`spinner-${this.variant}`]: this.variant };
    }
  }
};
</script>
<style lang="scss">
.etn-icon-wrapper {
  display: inline-block;
  height: 13px;
}

.etn-icon,
.etn-icon * {
  box-sizing: border-box;
}

.etn-icon.etn-lg {
  width: calc(60px * 0.5);
  height: calc(50px * 0.5);
}

.etn-icon.etn-2x {
  width: 60px;
  height: 50px;
}

.etn-icon {
  position: relative;
  width: calc(60px * 0.3);
  height: calc(50px * 0.3);

  filter: grayscale(100%);
}

.nav-link:hover .etn-icon,
.etn-icon:hover {
  filter: grayscale(0);
}

.etn-icon .left {
  position: absolute;
  opacity: 1;
  left: 25%;
  bottom: 0;
  background-color: rgb(39, 58, 74);
  width: 25%;
  height: 100%;
  transform: skew(-30deg);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 26%, 0 0);
  animation-delay: 200ms;
}

.etn-icon .right {
  position: absolute;
  opacity: 1;
  left: 57%;
  bottom: 0;
  background-color: rgb(101, 193, 152);
  width: 25%;
  height: 67%;
  transform: skew(30deg);
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 38%);
}
</style>
